import React from 'react';
import {  Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import StyleSwitcher from '../../components/StyleSwitcher'

// ----------------------------------------------------------------------

export default function MainLayout() {


  return (
    <>
        <Navbar />
        <Outlet />
        <Footer />
        <StyleSwitcher />
    </>
  );
}
