import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// guards
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// components
import LoadingScreen from '../components/Loading';
// import { PATH_AFTER_LOGIN } from '../config';


// ----------------------------------------------------------------------

const Loadable = (Component: React.FC) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Home />, index: true },
      ],
    },
    {
      path: '/Builder',
      element: <Builder />,
    },
    // USER LOGIN

    { path: '404', element: <NotFound /> },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/login')));
const SignUp = Loadable(lazy(() => import('../pages/auth/signup')));


// Main Routes


const Home = Loadable(lazy(() => import('../pages/webpage/Home')));
const Builder = Loadable(lazy(() => import('../pages/webpage/Builder')));
const NotFound = Loadable(lazy(() => import('../pages/Error')));

