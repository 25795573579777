import React, { createContext, useEffect, useReducer } from 'react';

// Utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

interface InitialState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: any;
}

const initialState: InitialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

interface Action {
  type: string;
  payload?: any;
}
interface Handlers {
    [key: string]: (state: InitialState, action: Action) => InitialState;
  }
  

const handlers:Handlers = {
  INITIALIZE: (state: InitialState, action: Action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: InitialState, action: Action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: InitialState) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: InitialState, action: Action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: InitialState, action: Action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

interface AuthContextProps extends InitialState {
  method: string;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
  signup: () => Promise<any>;
}

const AuthContext = createContext<AuthContextProps>({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(),
});

// -----------
// ----------------------------------------------------------------------


const AuthProvider: React.FC<{children:React.ReactNode}> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/user');
          const user  = response.data;
          console.log(user,"on reload")

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email:string, password:string) => {
    const response = await axios.post('/api/client/login', {
      email,
      password,
    });
    const { accessToken, user ,status} = response.data;
  
    if(status){

      setSession(accessToken);
      
    }
    else{
      return response

    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    return response

  };

  const signup = async () => {
    const response = await axios.post('/api/client/register', { });
    const { accessToken, user } = response.data;
    if(response.data.status === true ){

      setSession(accessToken);
      window.localStorage.setItem('accessToken', accessToken);
    }
  else{
      console.log("JWT COnsollee")
      console.log(response.data.errors)
      return response
    }
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
    return response
  };

  const logout = async () => {
    setSession(null);
    dispatch({
        type: 'LOGOUT',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        signup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
