import React from 'react';
import { Navigate } from "react-router-dom";
// hooks
import useAuth from '../hooks/useAuth';


const GuestGuard:React.FC<{children:React.ReactNode,}>=({children})=>{

    const { isAuthenticated ,user } = useAuth();
  if (isAuthenticated && user.visits === 1) {
    console.log(user.visits,"user.visits from Guest Gurads  1")
    return <Navigate to='user/editprofile' />;

  }
  if (isAuthenticated && user.visits > 1) {
    console.log(user.visits,"user.visits from Guest Gurads  >1")

    return <Navigate to='user/profile' />;
  }

  return <>{children}</>;

}
export default GuestGuard