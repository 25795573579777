import React, { useEffect } from "react";
import AppRouter from "./Routes";
import { BrowserRouter } from "react-router-dom";
import "./choices.min.css";
import "./App.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    (document.getElementById("theme-opt") as HTMLAnchorElement).href ="/css/style.min.css";
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer/>
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
