// routes
// import { PATH_USER } from './routes/paths.js';


// 
export const HOST_API="https://fxtrader.pythonanywhere.com"

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = '/user/editprofile'; // as '/dashboard/app'

