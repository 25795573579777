import React from 'react';
const Loading:React.FC = () => {
  return (
    <div id="preloader">
      {/* <div id="status"> */}
        {/* <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div> */}
        <img src="/images/loader.gif" alt="Loading"/>
      {/* </div> */}
    </div>
  )
}

export default Loading
